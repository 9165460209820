import React, {useRef} from "react";
import {useEffect, useState} from "react";
import {DefaultLoader} from "../../components/DefaultLoader";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {apiGetAdminExponat, apiPostFilterAdminExponat, apiPostPrintAdminExponat} from "./Actions";
import AdminExponatTable from "./AdminExponatTable";
import {Button} from "../../components/My/Button";
import {FiPlus} from "react-icons/fi";
import {ExponatAddToSectionModal} from "./ExponatAddToSectionModal";
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import InputField from "../../components/FinalForm/InputField";
import {shallowEqual, useSelector} from "react-redux";
import {SectionSelectField} from "../Section/SectionSelectField";
import SelectMultiField from "../../components/FinalForm/SelectMultiField";
import {useDispatch} from 'react-redux'
import SelectField from "../../components/FinalForm/SelectField";
import Toast from "cogo-toast";
import {FiPrinter} from "react-icons/all";
import ActionDropDown from "../../components/FinalForm/ActionDropDown";

const AdminExponat = (props) => {

    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [printing, setPrinting] = useState(false);
    let [selectedIds, setSelectedIds] = useState([]);
    let [assignToSectionOpen, setAssignToSectionOpen] = useState(false);

    let filterValues = null;
    const timer = useRef(null)
    const dispatch = useDispatch();
    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )

    const {activeAction} = useSelector(
        state => (state.activeAction),
        shallowEqual
    )
    let {filterCar, userState} = useSelector(
        state => ({
            filterCar: state.filterCar,
            userState: state.user
        }),
        shallowEqual
    );

    useEffect(() => {
        getData();
    }, [actualAction])


    const getData = () => {
        setLoading(true);
        onFilter(filterCar.filterCarData);


    }

    const state = [
        {value: 0, label: "Čeká na schválení"},
        {value: 1, label: "Schváleno"},
        {value: 2, label: "Je potřeba doplnit informace"},
        {value: 3, label: "Neschváleno"}
    ];

    const printed = [
        {value: -1, label: "Nevytištěno"},
        {value: 1, label: "Vytištěno"},
    ];

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám exponáty..."}/>;
        if (!loading && data) return <AdminExponatTable data={data} setSelectedIds={setSelectedIds}/>;
        else return <>Žádná data</>
    }

    const onFilter = (values) => {
        setLoading(true);
        dispatch({
            type: 'SET_FILTER_CAR',
            payload: values
        })
        dispatch({
            type: 'SET_CAR_PAGE',
            payload: 0,
        })
        apiPostFilterAdminExponat(actualAction.id, values, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const print = () => {
        if (selectedIds.length > 0) {
            setPrinting(true);
            apiPostPrintAdminExponat({id: selectedIds, type: 1,actionId:actualAction.id}, (data) => {
                const link = document.createElement('a');
                link.href = "data:application/pdf;base64," + data.data.data;
                link.setAttribute('download', data.data.fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                setPrinting(false);
            }, (error) => {
                Toast.error("Nepodařilo se připravit data k tisku.");
                setPrinting(false);
            })
        } else {
            Toast.error("Vyberte data k tisku.");
        }

    }

    const onChange = (name, value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            onFilter(filterValues);
        }, 1000)
    }

    const renderFilterForm = () => (
        <Widget>
            <div className={"text-sm"}>
                <Form onSubmit={onFilter} initialValues={filterCar.filterCarData}
                      render={({values}) => {
                          filterValues = values;
                          return (
                              <div className={"grid lg:grid-cols-4 grid-cols-2 gap-2"}>
                                  <div className={"mt-1"}>
                                      <InputField label={""} name={"name"} isMandatory={false} onChange={onChange}
                                                  placeholder={"Název"}/>
                                  </div>
                                  {userState.user.user_type===1?<SectionSelectField type={"filter"}
                                                      defaultValue={filterCar.filterCarData ? filterCar.filterCarData.name : null}
                                                      label={""} name={"section"} isMandatory={false}
                                                      placeHolder={"Sekce"}
                                                      isMulti={true} onChange={onChange}/>:<></>}
                                  <SelectMultiField
                                      defaultValue={filterCar.filterCarData ? filterCar.filterCarData.state : null}
                                      label={""} name={"state"} options={state} isMandatory={false} placeholder={"Stav"}
                                      isMulti={true} onChange={onChange}/>
                                  <SelectField
                                      defaultValue={filterCar.filterCarData ? filterCar.filterCarData.printed : null}
                                      label={""} name={"printed"} options={printed} isMandatory={false}
                                      placeholder={"Vytištěno"}
                                      onChange={onChange}/>
                                  <InputField label={""} name={"email"} isMandatory={false} onChange={onChange}
                                              placeholder={"E-mail"}/>
                              </div>
                          );

                      }}/>
            </div>
        </Widget>
    )

    const renderButtons = () => {
        let showSectionButton = (userState.user.user_type === 1 || userState.user.user_type === 2)  && actualAction && activeAction && actualAction.id===activeAction.id;
        let showPrintButton =  actualAction && activeAction && actualAction.id===activeAction.id;
        let cols = 3;
        if(!showSectionButton) cols--;
        if(!showPrintButton) cols--;
        return (
            <div className={`grid grid-cols-${cols} gap-2`}>
                {showPrintButton&& <Button text={"Tisk"} onClick={() => print()} icon={<FiPrinter/>} loading={printing}/>}
                {showSectionButton && <Button text={"Přiřadit sekci"} onClick={() => {
                    if (selectedIds.length > 0) {
                        setAssignToSectionOpen(true)
                    }else{
                        Toast.error("Vyberte data pro přiřazení do sekce.");
                    }
                }} icon={<FiPlus/>}/>}
                <ActionDropDown/>
            </div>
        )
    }

    return (
        <>
            <ExponatAddToSectionModal selectedIds={selectedIds} open={assignToSectionOpen}
                                      setOpen={setAssignToSectionOpen}/>
            <EnigooBreadcrumbs right={renderButtons()}/>
            {renderFilterForm()}
            {_renderBody()}
        </>
    )
}
export default AdminExponat;
